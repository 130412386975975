import comfort from "../images/gallery/comfort.jpg";
import pools from "../images/gallery/pools.jpg";
import panoramic from "../images/gallery/panoramic.jpg";
import food from "../images/gallery/food.jpg";
import panoramic2 from "../images/gallery/panoramic2.jpg";
import architecture from "../images/gallery/architecture.jpg";
import nature from "../images/gallery/nature.jpg";
import openarea from "../images/gallery/openarea.jpg";
import fountains from "../images/gallery/fountains.jpg";
import panoramic3 from "../images/gallery/panoramic3.jpg";
import world from "../images/gallery/world.jpg";
import seaview from "../images/gallery/seaview.jpg";
import luxury from "../images/gallery/luxury.jpg";
import welldesigned from "../images/gallery/welldesigned.jpg";
import floorceiling from "../images/gallery/floorceiling.jpg";
import panoramic4 from "../images/gallery/panoramic4.jpg";

export const features = [
    {
        image: `${comfort}`,
        title: "Ocean Front Suite",
        type: "Accomodations",
        description: "",
    },
    {
        image: `${pools}`,
        title: "Ocean Front Infinity Pools",
        type: "Amenities",
        description: "",
    },
    {
        image: `${panoramic}`,
        title: "Panoramic City Views",
        type: "Location",
        description: "",
    },
    {
        image: `${food}`,
        title: "Abaca Restaurant",
        type: "Amenities",
        description: "",
    },
    {
        image: `${panoramic2}`,
        title: "Green Principles",
        type: "Design ",
        description: "",
    },
    {
        image: `${architecture}`,
        title: "Cooling Gardens",
        type: "Design",
        description: "",
    },
    {
        image: `${nature}`,
        title: "Natural Features",
        type: "Design ",
        description: "",
    },
    {
        image: `${openarea}`,
        title: "Flowing Spaces",
        type: "Design",
        description: "",
    },
    {
        image: `${fountains}`,
        title: "Water Features ",
        type: "Design",
        description: "",
    },
    {
        image: `${panoramic3}`,
        title: "Thoughtful Architecture",
        type: "Design",
        description: "",
    },
    {
        image: `${world}`,
        title: "Traditional Elements",
        type: "Design",
        description: "",
    },
    {
        image: `${seaview}`,
        title: "Green Footprint",
        type: "Design",
        description: "",
    },
    {
        image: `${luxury}`,
        title: "Private Plunge Pools",
        type: "Accomodations",
        description: "",
    },
    {
        image: `${welldesigned}`,
        title: "Spa Bathroom Elements",
        type: "Accomodations",
        description: "",
    },
    {
        image: `${floorceiling}`,
        title: "Split Level Sky Villa",
        type: "Accomodations",
        description: "",
    },
    {
        image: `${panoramic4}`,
        title: "Ocean Front Suite",
        type: "Accomodations",
        description: "",
    },
];
