import production from './config.production.json';
import local from './config.local.json';
import staging from './config.staging.json';
import ConfigTypes from '../types/Config.type';

const config = (): ConfigTypes => {
    const LOCAL = 'localhost';
    const STAGING = 'staging';
    const IS_LOCAL = window.location.hostname.includes(LOCAL);
    const IS_STAGING = window.location.hostname.includes(STAGING);
    const IS_PRODUCTION = !IS_LOCAL && !IS_STAGING;

    const config: ConfigTypes = ((IS_LOCAL && local) || (IS_STAGING && staging) || (IS_PRODUCTION && production)) as ConfigTypes;
    return config;
}

export default config;
