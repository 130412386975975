import axios, { AxiosResponse } from 'axios';
import config from '../config';
import SubscribePayloadType from '../types/SubscribePayload.type';
import SubscribeResponseType from '../types/SubscribeResponse.type';


export const subscribe = async (payload: SubscribePayloadType): Promise<SubscribeResponseType> => {
  const url: string = config().API_URL + "/api/v1/subscribe";
	const formData = new FormData();
	
	Object.keys(payload).forEach(key => {
		formData.append(key, payload[key]);
	});
  
  if (payload.id) {
    return {
			success: true,
    };
  }

  const requestAppKeyResult: SubscribeResponseType = await axios({
    url,
    "method": "POST",
    "headers": {
      "content-type": "application/x-www-form-urlencoded",
    },
    "data": formData,
  })
    .then((response: AxiosResponse) => {
      return {
        success: true,
      };
    })
    .catch(error => {
      // unknown error
      console.error(error);
      return {
          success: false,
      };
    });

    return requestAppKeyResult;
};

const exportedVars = {
    subscribe,
};


export default exportedVars;
