import tfdlogo from "../images/logo/the-financial-district.png";
import dblogo from "../images/logo/designboom.png";
import walogo from "../images/logo/world-architecture.png";
import tatlerlogo from "../images/logo/tatler.png";
import sleeperlogo from "../images/logo/sleeper.png";

import hbdlogo from "../images/logo/hbdlogo.png";
import aasarlogo from "../images/logo/aasarlogo.png";
import cdnlogo from "../images/logo/cdnlogo.png";
import cslogo from "../images/logo/cslogo.png";
import sugbologo from "../images/logo/sugbologo.png";

import sunstarlogo from "../images/logo/sunstarlogo.png";
import thecebuanologo from "../images/logo/thecebuanologo.png";
import mblogo from "../images/logo/mblogo.png";
import yahoologo from "../images/logo/yahoologo.png";

export const articles = [
    {
        image: `${tfdlogo}`,
        title: "Cebu Firm Bares P2.5B Resort Development",
        link: "https://www.thefinancialdistrict.com.ph/post/cebu-firm-bares-p2-5b-resort-development",
        description: "",
    },
    {
        image: `${dblogo}`,
        title: "Büro Ole Scheeren unveils philippines resort with sky gardens and floating pools",
        link: "https://www.designboom.com/architecture/buro-ole-scheeren-the-abaca-resort-cebu-the-philippines-07-28-2021/?",
        description: "",
    },
    {
        image: `${walogo}`,
        title: "Büro Ole Scheeren Unveils Design For Abaca Resort Hotel In The Philippines",
        link: "https://worldarchitecture.org/architecture-news/evgmv/buro-ole-scheeren-unveils-design-for-abaca-resort-hotel-in-the-philippines.html…",
        description: "",
    },
    {
        image: `${tatlerlogo}`,
        title: "Abaca Resort: Büro Ole Scheeren's Latest Project Is A Nature-Inspired Hotel In Cebu",
        link: "https://www.tatlerasia.com/homes/property/ph-ole-scheerens-abaca-hotel-in-cebu",
        description: "",
    },
    {
        image: `${sleeperlogo}`,
        title: "Sleeper Feature: Meeting Ole Scheeren",
        link: "https://buro-os.com/upload/press/389f6126c7b414e3e8913279c79ce102.pdf",
        description: "",
    },

    {
        image: `${hbdlogo}`,
        title: "Ole Scheeren Envisions Abaca Resort in the Philippines",
        link: "https://hospitalitydesign.com/news/hotels-resorts/the-abaca-resort/",
        description: "",
    },
    {
        image: `${aasarlogo}`,
        title: "The Abaca Resort by Büro Ole Scheeren",
        link: "https://aasarchitecture.com/2021/09/the-abaca-resort-by-buro-ole-scheeren.html/",
        description: "",
    },
    {
        image: `${cdnlogo}`,
        title: "Cebu Landmasters unveils the new Abaca Resort Mactan, opening in 2024",
        link: "https://cebudailynews.inquirer.net/391107/cebu-landmasters-unveils-the-new-abaca-resort-mactan-opening-in-2024",
        description: "",
    },
    {
        image: `${cslogo}`,
        title: "Abaca Resort Mactan Hotel new design to embrace Philippine’s natural environment",
        link: "https://www.constructionspecifier.com/abaca-resort-mactan-hotel-new-design-to-embrace-philippines-natural-environment/",
        description: "",
    },
    {
        image: `${sugbologo}`,
        title: "Soon to rise: All-New Abaca Resort Mactan by Cebu Landmasters",
        link: "https://sugbo.ph/2021/abaca-resort-mactan/",
        description: "",
    },

    {
        image: `${sunstarlogo}`,
        title: "CLI strengthens hotel portfolio, unveils new Abaca Mactan Resort",
        link: "https://www.sunstar.com.ph/article/1902365/Cebu/Business/CLI-strengthens-hotel-portfolio-unveils-new-Abaca-Mactan-Resort",
        description: "",
    },
    {
        image: `${thecebuanologo}`,
        title: "P2.5B Abaca Resort Mactan by the Cebu Landmasters to Open in 2024",
        link: "https://thecebuano.com/2021/07/29/p2-5b-abaca-resort-mactan-by-the-cebu-landmasters-to-open-in-2024/",
        description: "",
    },
    {
        image: `${mblogo}`,
        title: "Luxury resort living: Cebu Landmasters sets the benchmark of tomorrow",
        link: "https://mb.com.ph/2021/07/28/luxury-resort-living-cebu-landmasters-sets-the-benchmark-of-tomorrow/",
        description: "",
    },
    // {
    //     image: `${mycebulogo}`,
    //     title: "Cebu Landmasters unveils new Abaca Resort Mactan; facility to open in 2024",
    //     link: "https://mycebu.ph/article/abaca-resort-mactan/",
    //     description: "",
    // },
    {
        image: `${yahoologo}`,
        title: "CLI unveils new Abaca Resort Mactan, completion eyed in 2024",
        link: "https://ph.news.yahoo.com/cli-unveils-abaca-resort-mactan-092700139.html",
        description: "",
    },
    // {
    //     image: `${boomerslogo}`,
    //     title: "DESIGN VIEWS: ‘THE ABACA RESORT’ IN THE PHILIPPINES",
    //     link: "https://boomers-daily.com/2021/07/28/design-views-the-abaca-resort-in-the-philippines/",
    //     description: "",
    // },
];

