import React, { useState, useEffect } from "react";
import favicon from '../images/favicon.ico';
import seoImg from "../images/meta-img.jpg";
import Helmet from 'react-helmet';
import "bootstrap/dist/css/bootstrap.min.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";
import { features } from "../constants/gallery";
import { articles } from "../constants/press";

import Modal from "./components/Modal";
import VisitorForm from "./components/VisitorForm";

// import ArrowRightDropCircleOutlineIconMdi from "mdi-react/ArrowRightDropCircleOutlineIcon";
import pattern from "../images/pattern.jpg";
import abacaResortMactanLogo from "../images/logo/abaca-resort-mactan.svg";
import abacaResortLogo from "../images/logo/abaca-resort.png";
import aideaLogo from "../images/logo/aidea.svg";
import closeImage from "../images/close.png";
import buroLogo from "../images/logo/buro.svg";
import cebuLandmastersLogo from "../images/logo/Cebu-Landmasters-Horizontal-White_1.svg";
import vidPlayIcon from "../images/vid-play-icon.svg";

import "../styles/index.css";
import styled, { css } from "styled-components";
import SubscribePayloadType from "../types/SubscribePayload.type";
import { subscribe } from "../services/RuleNumberOneApi";

// styles
const bgPattern = {
    backgroundImage: `url(${pattern})`,
};

// markup
const IndexPage = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [isFormOpen, setFormOpen] = useState(false);
    const [isShowAll, setIsShowAll] = useState(false);
    const delay = 5;

    useEffect(() => {
        caches.has("NewsLetterCache").then((hasCache) => {
            if (hasCache) {
                setFormOpen(false);
            } else if (!hasCache) {
                let timer1 = setTimeout(() => setFormOpen(true), delay * 1000);
                return () => {
                    clearTimeout(timer1);
                };
            }
        });

        // let timer1 = setTimeout(() => setFormOpen(true), delay * 1000);
        //     return () => {
        //     clearTimeout(timer1);
        // };
    }, []);

    function openModal() {
        setModalOpen(!isModalOpen);
    }

    const handleSubscribe = async (payload, cb) => {
        const result = await subscribe(payload);
        console.log(result);
        if (result.success) {
            setFormOpen(false);
        }
        cb(true);
    }

    const closeFormModal = () => {
        setFormOpen(false);
    }

    const pressItems=articles.map((article, i) => {
        if (!isShowAll && i>5){
            return null
        }
        return (
            <Col className="pressArticle" key={article.title}> <div className="pressItem">
                <img
                    src={article.image}
                    alt=""
                    className=""
                />
                <div className="pressItemTitle">
                    <a href={article.link} target="_blank">
                        {article.title}
                    </a>
                </div>
            </div>
        </Col>
        )
    }   
    )

    return (
        <main style={bgPattern}>
            <Helmet>
                <title>Abaca Resort Mactan</title>
                <link rel="icon" href={favicon} />
                <meta name="description" content="The new and re-imagined Abaca Resort Mactan is a collaboration between Filipino and International talent. The development is led by Cebu Landmasters, Inc. and CLI Premier Hotels, Inc. As the region’s leading local developer, CLI has brought together some of the finest talent in the industry for Abaca Resort." />
                <meta property="og:url"  content="https://www.abacaresort.com/" />
                <meta property="og:image" content={seoImg} />
            </Helmet>
            {/* Hero */}
            <Container fluid className="heroSection">
                <img
                    src={abacaResortMactanLogo}
                    alt="Abaca Resort Mactan Logo"
                    className="d-block mx-auto"
                />
            </Container>

            <Container fluid className="videoThumbnailContainer">
                <img src={vidPlayIcon} alt="Play Video" onClick={openModal} className="playVideo"/>
                <p>Watch Video</p>
                {isModalOpen && (
                    <Modal
                        isModalOpen={isModalOpen}
                        openModal={openModal}
                    />
                )}
            </Container>

            <section className="aboutSection">
                <Container>
                    <Row className="justify-content-center justify-content-xl-between">
                        <Col xs={12} sm={12} lg={12} xl={7} className="aboutBrandGroup">
                            <Row xs={1} sm={1} lg={2}>
                                <Col>
                                    <div className="aboutBrandItem cebuLandmasters">
                                        <a href="https://www.cebulandmasters.com/">
                                            <img
                                                src={cebuLandmastersLogo}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </a>
                                        <p className="textSquash">Abaca Resort Mactan Developer</p>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="aboutBrandItem">
                                        <a href="https://theabacagroup.com/">
                                            <img
                                                src={abacaResortLogo}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </a>
                                        <p className="textSquash">
                                            Hotel Partner<br/>
                                            Food and Beverage Partner
                                        </p>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="aboutBrandItem">
                                        <a href="https://buro-os.com/">
                                            <img
                                                src={buroLogo}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </a>
                                        <p className="textSquash">
                                            Concept and Vision<br/>
                                            Architectural Design<br/>
                                            Interior Design
                                        </p>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="aboutBrandItem">
                                        <a href="https://www.aidea.com.ph/">
                                            <img
                                                src={aideaLogo}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </a>
                                        <p className="textSquash">
                                            <span className="callout3">Local Counterpart</span><br/>
                                            Architectural and Interior Design<br/>
                                            Back-of-House Interior Design <br/>
                                            Environmental Graphic Design
                                        </p>
                                    </div>
                                </Col>
                                <Col sm={12}>
                                    <div className="aboutRooms">
                                        <div className="roomTitle">
                                            <h3 className="textSquash">Room Inventory</h3>
                                            <p className="callout1">125 Suites & Villas</p>
                                        </div>
                                        
                                        <div className="roomsGroup">
                                            <div className="roomsItem">
                                                <p className="h4">94</p>
                                                <p className="callout1">Studio Sky Suites</p>
                                                <p className="caption1">50+ m²</p>
                                            </div>
                                            <div className="roomsItem">
                                                <p className="h4">12</p>
                                                <p className="callout1">ONE BEDROOM SKY VILLAS</p>
                                                <p className="caption1">80+ m²</p>
                                            </div>
                                            <div className="roomsItem">
                                                <p className="h4">13</p>
                                                <p className="callout1">ONE BEDROOM SKY VILLAS with pool</p>
                                                <p className="caption1">85+ m²</p>
                                            </div>
                                            <div className="roomsItem">
                                                <p className="h4">6</p>
                                                <p className="callout1">TWO BEDROOM SKY VILLAS with pool</p>
                                                <p className="caption1">105+ m²</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            
                        </Col>
                        <Col xs={12} sm={12} lg={8} xl={4}>
                            <p className="body2 textSquash">The new and re-imagined Abaca Resort Mactan is a collaboration between Filipino and International talent. The development is led by Cebu Landmasters, Inc. and CLI Premier Hotels, Inc. As the region’s leading local developer, CLI has brought together some of the finest talent in the industry for Abaca Resort.</p>

                            <p className="body2 textSquash">The Resort will be operated by The Abaca Group, who brings close to 20 years of experience in Cebu’s Hospitality scene to the table. The Abaca Group is the owner and operator of multiple restaurant brands and the original highly rated and award winning, Abaca Boutique Resort.</p>

                            <p className="body2 textSquash">The new Abaca Resort will feature 125 butler serviced beachside suites and sky villas, many with their own private plunge pools. There will be multiple swimming pools, generous function rooms, several food and beverage operations, a boutique gym with yoga and meditation facilities, a kids lounge and a luxurious spa as its main amenities.</p>

                            <p className="body2 textSquash">It's iconic design is led by renowned international architectural firm Büro-Ole Scheeren, whose principal architect is the German-born, Ole Scheeren. His most recently completed projects include the Guardian Art Center in Beijing, a hybrid arts space and the world’s first custom-built auction house; MahaNakhon Tower in Bangkok; and DUO, a large-scale development in Singapore, to name a few of its award-winning large scale, mixed-use projects.</p>

                            <p className="body2 textSquash">Acting as the local counterpart for Architectural and Interior Design is Filipino Owned and Internationally recognized architecture firm, Aidea, Inc. In their 25 years of existence, Aidea has provided design and technology solutions for companies locally and overseas. Their work can be found in 90 cities in 60 countries spanning 5 continents.</p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="gallerySection">
                <Container>
                    <Row>
                        <Col className="gallerySectionTitle">
                            <h3>New and Reimagined</h3>
                        </Col>
                    </Row>
                    <Row xs={1} sm={1} lg={2}>
                        {features.map((feature) => (
                            <Col key={feature.title}>
                                <div className="galleryItem">
                                    <img
                                        src={feature.image}
                                        alt=""
                                        className="img-fluid"
                                    />
                                    <div className="galleryItemTitle">
                                        <h4>{feature.title}</h4>
                                        <p>{feature.type}</p>
                                    </div>
                                    <p className="galleryItemDesc">
                                        {feature.description}
                                    </p>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>

            <section className="pressSection">
                <Container>
                    <Row>
                        <Col className="pressSectionTitle">
                            <h3>On the Press</h3>
                        </Col>
                    </Row>
                    <Row xs={1} sm={2} lg={3}>
                        {pressItems}
                    </Row>
                    <Row className="viewMore">
                        {!isShowAll && <button onClick={()=>{
                            setIsShowAll(true)
                        }}>View More</button>}
                    </Row>
                </Container>
            </section>

            {isFormOpen && (
                <div className="modalWrapper">
                    <section className="signupSection">
                        <Container>
                            <Row>
                                <ModalFormContainer
                                    className={"col-11"}
                                >
                                    <Row
                                        sm={1}
                                        xl={2}
                                        className="justify-content-center"
                                    >
                                        <Col
                                            lg={5}
                                            className="d-flex align-items-center justify-content-center"
                                            id="first-column"
                                        >
                                            <div className="signupSectionTitle">
                                                <p className="display2">
                                                    New and Reimagined
                                                </p>
                                                <p className="display1">2024</p>
                                            </div>
                                        </Col>
                                        <Col
                                            xl={6}
                                            className="d-flex align-items-center justify-content-center"
                                        >
                                            <VisitorForm
                                                onSubscribe={(payload, cb) => {
                                                    handleSubscribe(payload, cb);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </ModalFormContainer>
                                <a onClick={closeFormModal} className="close-modal">
                                    <img src={closeImage} alt="Close subscribe modal"/>
                                </a>
                                {/* <CloseButton className={"col-1"}>
                                    <a onClick={closeFormModal}>
                                        <img src={closeImage} alt="Close subscribe modal"/>
                                    </a>
                                </CloseButton> */}
                            </Row>
                        </Container>
                    </section>
                </div>
            )}

            <footer>
                <a href="https://theabacagroup.com/">
                    <span>© Abaca Hotel and Resorts 2021</span>
                </a>
            </footer>
        </main>
    );
};

// const PlayIcon = styled(ArrowRightDropCircleOutlineIconMdi)`
//     color: white;
//     cursor: pointer;
// `;

const ModalFormContainer = styled(Col)`
    padding-left: 30px;
    padding-right: 0;
    margin: 0;
    @media only screen and (min-width: 768px) {
        padding-left: 55px;
        padding-right: 10px;
        margin: 0;
    }
`;

// const CloseButton = styled(Col)`
//     text-align: center;
//     margin-top: -35px;

//     @media only screen and (min-width: 1200px) {
//         margin-top: -50px;
//     }
    
//     a {
//         cursor: pointer;
//     }
// `;

export default IndexPage;
